/*--------------------------------------------------
Video Home
--------------------------------------------------*/
::selection {
    background: darken(rgba(39,197,247,1), 5%);
    color: #fff;
}

#videoHome {
    position: relative;
    z-index: 0;
    max-height: calc(98vw * 9 / 16);
    height: 100vh;
    overflow: hidden;
    background: rgba(39,197,247,1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'FFClanWebMedium', Arial, sans-serif;

    @media (max-width: 767px) {
        max-height: calc(95vw * 9 / 16);
    }

    .video {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        @media (max-width: 767px) {
            background: url(/static_video/video-home-5.gif) no-repeat center / cover transparent;
        }

        video {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);

            @media (max-width: 767px) {
                display: none;
            }
        }
    }


    .claim {
        position: relative;
        z-index: 10;
        display: flex;
        justify-content: center;
        /*align-items: baseline;*/
        font-size: 5.5vw;
        line-height: 1;
        text-align: center;
        color: #fff;

        @media (min-width: 1920px) {
            font-size: 100px;
        }

        & > * {
            padding: 0 10px;

            @media (max-width: 480px) {
                padding: 0 4px;
            }
        }

        a {
            color: #fff;

            span {
                position: relative;
                z-index: 1;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 6%;
                    height: 5%;
                    border-radius: 10px;
                    width: 100%;
                    background: #fff;

                    @media (max-width: 768px) {
                        height: 4px;
                        bottom: 1px;
                    }

                    @media (max-width: 575px) {
                        height: 2px;
                        bottom: 1px;
                    }
                }
            }
        }
    }

    .claim__word {
        font-weight: 700;
        display: flex;

        span {
            display: none;
            transform-origin: 0 center;
        }
    }

    .claim__cursor {
        animation: cursor .95s forwards infinite;
    }
}


@keyframes cursor {
    0% {
        opacity: 0;
    }

    49% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

